import { gsap, TweenLite, TweenMax, } from "gsap/all";
gsap.registerPlugin(TweenLite, TweenMax); 

export default($) => ({
	scrollHedear() {
		$(window).scroll(function() {
			if($(window).scrollTop() > 0) {
					$('header').addClass('float');
				} else {
					$('header').removeClass('float');
				}
		});	
	},
	menuMobile() {

		$('.burger-menu').on('click', function(){
			let e_menu = $('header .wrapper nav'),
					e_ul = e_menu.find('> ul');
			if(! $(this).hasClass('active')) {
				$(this).parents('header').addClass('head-active');
				$(this).addClass('active'); 			
				$('body').addClass('lock');	
				gsap.to(e_menu, .5, {
					css: {
						left: '0'
					},
					ease: 'power2.easeOut'
				});
				gsap.to(e_ul, .5, {
					css: {
						left: '0'
					},
					ease: 'power2.easeOut'
				});	
			} else {
				$('body').removeClass('lock');
				$(this).parents('header').removeClass('head-active');
				$(this).removeClass('active'); 				
				gsap.to(e_menu, .5, {
					css: {
						left: '-100%'
					},
					ease: 'power2.easeOut'
				});
				gsap.to(e_ul, .5, {
					css: {
						left: '-100%'
					},
					ease: 'power2.easeOut'
				});	
			}			

		});	

		$('.close-mob').on('click', function(){
			let e_menu = $('header .wrapper nav'),
					e_ul = e_menu.find('> ul');
			$('body').removeClass('lock');
			$('header.head-active').removeClass('head-active');
			$('.burger-menu').removeClass('active'); 				
			gsap.to(e_menu, .5, {
				css: {
					left: '-100%'
				},
				ease: 'power2.easeOut'
			});
			gsap.to(e_ul, .5, {
				css: {
					left: '-100%'
				},
				ease: 'power2.easeOut'
			});
		});

		$('nav li .parent').on('click', function(){
			if(! $(this).parent().hasClass('act')) {
				$(this).parent().addClass('act');
				$(this).parent().siblings('li').removeClass('act');
				$(this).parent().siblings('li').find('.child-menu').slideUp(200); 
				$(this).parent().find('.child-menu').slideDown(200); 
			} else {
				$(this).parent().removeClass('act');
				$(this).parent().find('.child-menu').slideUp(200);
			}
		});
	
	},
	searchMob() {
		if($(window).width() < 768) {
			$('.search .ico-mob').on('click', function(){
				if(! $(this).parent().hasClass('show')) {
					$(this).parent().addClass('show')
				} else {
					$(this).parent().removeClass('show')				
				}
			});
		}		
	},
  searchDropdown() {
    document.querySelector("header .search > img").addEventListener("click", function () {
      var searchContainer = this.parentNode;
      var searchBox = searchContainer.querySelector(".search-box");

      if (searchContainer.classList.contains("active")) {
        searchContainer.classList.remove("active");
        TweenLite.to(searchBox, 0.3, {
          css: {
            opacity: "0",
            "margin-top": "20px"
          },
          ease: "Power2.easeOut",
          onComplete: function () {
            searchBox.style.display = "none";
          }
        });
      } else {
        TweenLite.to(searchBox, 0.3, {
          css: {
            display: "none",
            opacity: "0",
            "margin-top": "20px"
          },
          ease: "Power2.easeOut"
        });
        searchContainer.classList.add("active");
        TweenLite.to(searchBox, 0.3, {
          css: {
            display: "block",
            opacity: "1",
            "margin-top": "0px"
          },
          ease: "Power2.easeOut"
        });

        setTimeout(function () {
          searchBox.querySelector("form input.input-focus").focus();
        }, 300);
      }
    });

    document.addEventListener("mouseup", function (e) {
      var searchContainer = document.querySelector("header .search");
      var searchBox = document.querySelector(".search-box");

      if (!searchContainer.contains(e.target)) {
        searchContainer.classList.remove("active");
        TweenLite.to(searchBox, 0.3, {
          css: {
            opacity: "0",
            "margin-top": "20px"
          },
          ease: "Power2.easeOut",
          onComplete: function () {
            searchBox.style.display = "none";
          }
        });
      }
    });

    document.querySelector("header .search .search-box .close").addEventListener("click", function (e) {
      var searchContainer = this.closest('.search');
      var searchBox = searchContainer.querySelector(".search-box");

      if (searchContainer.classList.contains("active")) {
        searchContainer.classList.remove("active");
        TweenLite.to(searchBox, 0.3, {
          css: {
            opacity: "0",
            "margin-top": "20px"
          },
          ease: "Power2.easeOut",
          onComplete: function () {
            searchBox.style.display = "none";
          }
        });
      }
    })
  },
  languageDropdown() {
    document.querySelector("header .language > span").addEventListener("click", function () {
      var langContainer = this.parentNode;
      var langBox = langContainer.querySelector(".language-box");

      if (langContainer.classList.contains("active")) {
        langContainer.classList.remove("active");
        TweenLite.to(langBox, 0.3, {
          css: {
            opacity: "0",
            "margin-top": "20px"
          },
          ease: "Power2.easeOut",
          onComplete: function () {
            langBox.style.display = "none";
          }
        });
      } else {
        TweenLite.to(langBox, 0.3, {
          css: {
            display: "none",
            opacity: "0",
            "margin-top": "20px"
          },
          ease: "Power2.easeOut"
        });
        langContainer.classList.add("active");
        TweenLite.to(langBox, 0.3, {
          css: {
            display: "block",
            opacity: "1",
            "margin-top": "0px"
          },
          ease: "Power2.easeOut"
        });
      }
    });

    document.addEventListener("mouseup", function (e) {
      var langContainer = document.querySelector("header .language");
      var langBox = document.querySelector(".language-box");

      if (!langContainer.contains(e.target)) {
        langContainer.classList.remove("active");
        TweenLite.to(langBox, 0.3, {
          css: {
            opacity: "0",
            "margin-top": "20px"
          },
          ease: "Power2.easeOut",
          onComplete: function () {
            langBox.style.display = "none";
          }
        });
      }
    });
  },
	init() {
		this.scrollHedear(); 
		this.menuMobile(); 
		this.searchMob();
		this.searchDropdown();
		this.languageDropdown();
	}
});
