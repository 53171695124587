import * as $ from 'jquery';
import header  from './libs/header.js';
import popup  from './libs/popup.js'
import inputFile from './libs/fileInput.js';
import responsiveImage from './libs/responsive-images';

window.$ = $

$(document).ready(() => {
	header($).init()
	popup($).outsidePopup()
	inputFile($).fileInput($('form .wrap-input input[type=file]'),{class_name:'input_file'});
	inputFile($).fileInput($('form .wrap-input-lg input[type=file]'),{class_name:'input_file_lg'});
	responsiveImage($).init($('.bannerhome figure img, .banner-middle figure img'), {
		type: 'image' //type image = <img>  or bg = background: url(...)
	});
})

window.openPopup = (id) => {
	popup($).openpopup(id)
}
