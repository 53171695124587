import { gsap, TweenLite, TweenMax } from "gsap/all";
gsap.registerPlugin(TweenLite, TweenMax); 

export default ($) => ({
	openpopup(id){
        // $('.popup'+id).fadeIn(300);
        // $('body').addClass('lock');
        let elem = $('.popup'+id);     
        $('body').addClass('lock');  
        TweenLite.set(elem, {'display':'flex', opacity:0}); 
        TweenLite.to(elem, .3, {opacity:1,ease:'power2.out'});
    },
    closepopup(id){
        // $('.popup'+id).fadeOut(300);
        TweenLite.to($('.popup'+id), .3, {'display':'none','opacity':0,ease:'power2.out'});
      	$('body').removeClass('lock');  
    },
    btnpopup(){
        const _th = this
        $('.overlay').on('click', function(e){
            e.preventDefault();
            const idpopup = '#'+$(this).parents('.popup').attr('id')
            _th.closepopup(idpopup)

            $('.popup').each(function(){
                let src = $(this).find('ifarme').attr('src')
                $(this).find('iframe').attr('src','')
                $(this).find('iframe').attr('src',src)
            })
        })
    },
    outsidePopup() {
        const _th = this
        $('.popup').on('click', function(event) {       
            let $target = $(event.target);
            let idpopup = '#'+$(this).attr('id');  
            if(!$target.closest('.popup .popup-inner').length) {            
                // TweenLite.to($(this), .3, {display:'none', opacity:0,ease:'power2.out'});
                _th.closepopup(idpopup)
                $('body').removeClass('lock');
                $('.popup').each(function(){
                    let src = $(this).find('ifarme').attr('src')
                    $(this).find('iframe').attr('src','')
                    $(this).find('iframe').attr('src',src)
                })
            }                       
        });
    },
    init(){
        this.outsidePopup()
        // this.btnpopup()
    }
})