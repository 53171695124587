export default($) =>({
  fileInput(elem,e) {
      elem.wrap('<div class="' + e.class_name + '"></div>');
      elem.css({
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0
      });
      elem.parent('.' + e.class_name).css({
          position: 'relative',
          width: elem.outerWidth() - 2,
          height: elem.outerHeight() - 2,
          display: 'inline-block'
      });
      elem.parent('.' + e.class_name).append("<span>"+elem.attr('placeholder-text')+"</span>");
      elem.on('change', function () {
          let value = $(this).val();
          if (value != "") {
              value = value.substring(12, value.length);
              $(this).next("span").html(value);
              $(this).parent().addClass('success')               
          } else {
              $(this).next("span").html(elem.attr('placeholder-text'));
              $(this).parent().removeClass('success')
          }
      });
  }
})
